import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AppSettings } from '@buyiq-core/models/app-settings';
import { DataResource } from '@buyiq-custom-types';
import {
    ApiCollectionResponse,
    ParameterBuilder,
    SearchState,
} from '@cia-front-end-apps/shared/api-interaction';
import { Observable, of, timeout } from 'rxjs';
import { Promotion, PromotionParameters } from '../models/promotion';
import { catchError } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PromotionResource implements DataResource<Promotion> {
    private baseUrl: string;

    constructor(private http: HttpClient, private appSettings: AppSettings) {
        this.baseUrl = `${this.appSettings.apis.promo}`;
    }

    getAll(
        searchState: SearchState,
        promotionParameters: PromotionParameters
    ): Observable<ApiCollectionResponse<Promotion>> {
        const url = `${this.baseUrl}/promotions`;
        let params = new HttpParams({
            fromObject: {
                pageSize: (searchState.pageSize || 25).toString(),
                sortBy: searchState.sortBy || 'description',
                pageIndex: (searchState.pageIndex || 0).toString(),
                sortDirection: searchState.sortDirection || 'asc',
                storeId: (promotionParameters.storeId || '').toString(),
                startDate: promotionParameters.startDate?.toISOString() || '',
                endDate: promotionParameters.endDate?.toISOString() || '',
                upc: promotionParameters.upc || '',
            },
        });
        params = ParameterBuilder.buildArrayParameter(
            promotionParameters.advertiserIds,
            'advertiserIds',
            params
        );
        params = ParameterBuilder.buildArrayParameter(
            promotionParameters.promoTypes,
            'promoTypes',
            params
        );

        return this.http.get<ApiCollectionResponse<Promotion>>(url, { params });
    }
}
